import React, { useEffect, useMemo, useState } from "react";
import styles from "./userSettings.module.scss";
import { UsersSide } from "./users";
import { UserInfo } from "./info";
import { getAllRolesData, getEmployeesData } from "./services";
import { useAppDispatch } from "../../redux";
import { activeValues, ActiveValuesKeysType } from "./users/filters";
import { Employee } from "../../types";

export type FiltersType = {
  search?: string;
  is_active?: ActiveValuesKeysType;
  group_id: string | number;
  position_id: string | number;
};
export const UserSettings = () => {
  const dispatch = useAppDispatch();
  const [activeUser, setActiveUser] = useState<Employee | null>(null);
  const [data, setData] = useState<Employee[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [filters, setFilters] = useState<FiltersType>({
    search: "",
    is_active: activeValues.active,
    group_id: "",
    position_id: "",
  });

  useEffect(() => {
    dispatch(getAllRolesData());
  }, [dispatch]);

  useEffect(() => {
    const getEmployees = async () => {
      const res = await getEmployeesData(
        {
          group_id: filters.group_id,
          position_id: filters.position_id,
        },
        setIsFetching
      );
      setData(res);
    };
    getEmployees();
  }, [filters.group_id, filters.position_id]);

  const changeActiveUser = (user) => setActiveUser(user);

  const changeCities = (employee_id: number, city_name: string) => {
    setData((prev) =>
      prev?.map((item) => {
        if (item?.employee_id === employee_id) {
          return { ...item, city_name };
        }
        return item;
      })
    );
  };

  const handleFiltersChange = async (name, value) => {
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const filtersAsParams: FiltersType = useMemo(() => {
    return {
      group_id: filters.group_id,
      position_id: filters.position_id,
    };
  }, [filters]);

  return (
    <div className={styles.settings__wrapper}>
      <div className={styles.settings__users}>
        <UsersSide
          data={data}
          filtersAsParams={filtersAsParams}
          filters={filters}
          handleFiltersChange={handleFiltersChange}
          setData={setData}
          isFetching={isFetching}
          changeActiveUser={changeActiveUser}
          activeUserId={activeUser?.employee_id}
        />
      </div>
      <div className={styles.settings__info}>
        <UserInfo activeUser={activeUser} changeCities={changeCities} />
      </div>
    </div>
  );
};
