import { appAPI } from "./api_app";
import { CommonsType, Company, Position } from "../types";

export const getCommons = async (): Promise<CommonsType> => {
  try {
    const res = await appAPI.getCommons();
    return res?.data;
  } catch (e) {}
};
export const getPositions = async (): Promise<Position[]> => {
  try {
    const res = await appAPI.getPositions();
    return res?.data;
  } catch (e) {}
};
export const getCompanies = async (): Promise<Company[]> => {
  try {
    const res = await appAPI.getCompanies();
    return res?.data;
  } catch (e) {}
};
