import { TextField } from "@mui/material";
import React from "react";
import NumberFormat from "react-number-format";

export const InputNumberMask = ({
  name,
  label,
  value,
  onChange,
  error,
  variant = "standard",
  onKeyDown,
  placeholder = "",
  className = "m-form",
  format,
}) => {
  return (
    <TextField
      fullWidth
      label={label}
      value={value}
      onChange={onChange}
      name={name}
      className={className}
      id={name}
      //@ts-ignore
      variant={variant || "standard"}
      onKeyDown={onKeyDown}
      inputProps={{
        format,
        placeholder,
      }}
      InputProps={{
        inputComponent: NumberFormatCustom,
      }}
      helperText={error}
      error={!!error}
    />
  );
};

const NumberFormatCustom = (props) => {
  const { inputRef, onChange, format, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format={format || ""}
      mask="_"
    />
  );
};

export const CardInput = (props) => (
  <InputNumberMask {...props} format={"#### #### #### ####"} />
);
export const PhoneInput = (props) => (
  <InputNumberMask {...props} format={"+# (###) ###-##-##"} />
);
