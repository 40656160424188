export const API_URL = process.env.REACT_APP_API_URL;

export const PAGES = {
  main: "/",
  login: "/login",
  permissions: "/manage-access",
  compareDataOneC: "/compare-data-one-c",
  userSettings: "/user-settings",
  reportAccessList: "/report-access-list",
  forgetPassword: "/forget-password",
} as const;

export type PAGES_VALUES = typeof PAGES[keyof typeof PAGES];

export const LOCALSTORAGEFIELDS = {
  fingerToken: "fingerToken",
};

export const COOKIES_FIELDS = {
  city: "city",
  accessToken: "accessTokenAdmin",
  refreshToken: "refreshTokenAdmin",
  userData: "userDataAdmin",
};

export const MODALS_NAMES = {
  confirmModal: "confirmModal",
  addMenuItem: "addMenuItem",
  textModal: "textModal",
  giveTemporarilyAccess: "giveTemporarilyAccess",
  addGroup: "addGroup",
  addEmployee: "addEmployee",
  addGrantItem: "addGrantItem",
  duplicatePermissions: "duplicatePermissions",
} as const;
export const DRAWER_WIDTH = 285;
