import { Checkbox, IconButton } from "@material-ui/core";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import clsx from "clsx";
import React, { useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { EditIconBtn, useBlockStyles } from "../../../components/blocks";
import {
  CustomTable,
  CustomTableCell,
  CustomTableRow,
} from "../../../components/blocks/CustomTable/CustomTable";
import { CButton } from "../../../components/buttons";
import { ConfirmModalData } from "../../../components/popup/types";
import { MODALS_NAMES } from "../../../constants";
import { useAppDispatch } from "../../../redux";
import { closePopUp, showPopUp } from "../../../redux/reducers/appReducer";
import { Employee } from "../../../types";
import { getLocaleDate } from "../../../utils";
import { useHttp } from "../../../utils/hooks/http.hook";
import { useCustomSnackbar } from "../../../utils/hooks/useCustomSnackbar";
import { filterUsers } from "../services";
import { ChangeEmployeePasswordRes, RequestEmployeesType } from "../types";
import { FiltersType } from "../UserSettings";
import styles from "../userSettings.module.scss";
import { UsersFilters } from "./filters";

const FILTERS_QUERY_PARAMETER = "filters";

type UsersSideType = {
  activeUserId: number;
  changeActiveUser: (v: Employee) => void;
  isFetching?: boolean;
  filtersAsParams?: FiltersType;
  filters?: FiltersType;
  handleFiltersChange: (n: string, b: string) => void;
  data: Employee[];
  setData: (n: Employee[]) => void;
};
export const UsersSide = ({
  activeUserId,
  changeActiveUser,
  isFetching,
  filtersAsParams,
  filters,
  handleFiltersChange,
  data,
  setData,
}: UsersSideType) => {
  const dispatch = useAppDispatch();
  const classes = useBlockStyles();
  const [params, setSearchParams] = useSearchParams();
  const { request, loading } = useHttp();
  const { addDefaultSnack } = useCustomSnackbar();

  const filtersQuery = params.get(FILTERS_QUERY_PARAMETER);
  const [showFilters, setShowFilters] = useState(filtersQuery === "true");

  const onSubmit = async (data) => {
    const res = await request<RequestEmployeesType>(
      "/core/employees/",
      "post",
      data,
      {},
      { isModal: true, params: filtersAsParams }
    );
    if (res?.status) {
      addDefaultSnack("Пользователь успешно создан");
      setData(res?.employees);
      dispatch(closePopUp());
    }
  };

  const onAddClick = () => {
    dispatch(showPopUp(MODALS_NAMES.addEmployee, { onSubmit }));
  };

  const onSubmitUpdate = async (data, employee_id) => {
    const res = await request<RequestEmployeesType>(
      `/core/employees/${employee_id}/`,
      "put",
      data,
      {},
      { isModal: true, params: filtersAsParams }
    );
    if (res?.status) {
      addDefaultSnack("Пользователь успешно изменен");
      setData(res?.employees);
      dispatch(closePopUp());
    }
  };

  const onEditClick = (body) => {
    dispatch(
      showPopUp(MODALS_NAMES.addEmployee, {
        onSubmit: (data) => onSubmitUpdate(data, body?.employee_id),
        body,
      })
    );
  };

  const actionWithUser = async (employee_id) => {
    const res = await request<RequestEmployeesType>(
      `/core/employees/activate/`,
      "post",
      { employee_id },
      {},
      { isModal: true, params: filtersAsParams }
    );
    if (res?.status) {
      setData(res?.employees);
      dispatch(closePopUp());
    }
  };

  const onClickReset = (id, fio) => {
    dispatch(
      showPopUp(MODALS_NAMES.confirmModal, {
        onSubmit: () => resetUserPassword(id, fio),
        close: true,
        btnLabel: "Сбросить",
        description: "Вместо старого пароля будет сформирован новый пароль",
        title: `Вы действительно хотите сбросить пароль пользователя - "${fio}"?`,
      } as ConfirmModalData)
    );
  };

  const resetUserPassword = async (employee_id, fio) => {
    const res = await request<ChangeEmployeePasswordRes>(
      `/core/employees/drop-password/ `,
      "post",
      { employee_id },
      {},
      { isModal: true }
    );
    if (res?.status) {
      dispatch(
        showPopUp(MODALS_NAMES.textModal, {
          title: `Пароль пользователя "${fio}" успешно сброшен`,
          description: `Новый пароль: `,
          addInfo: `Передайте пароль пользователю для входа в систему`,
          value: res?.new_password,
          close: true,
        })
      );
    }
  };

  const filteredData = useMemo(() => {
    return filterUsers(data, {
      search: filters.search,
      is_active: filters.is_active,
    });
  }, [data, filters.search, filters.is_active]);

  const toggleShowFilters = () => {
    setShowFilters((prev) => {
      const isOpen = !prev;
      params.set(FILTERS_QUERY_PARAMETER, String(isOpen));
      setSearchParams(params);
      return isOpen;
    });
  };

  return (
    <div>
      <div className={styles.settings__header}>
        <CButton
          className={styles.settings__header_filtersBtn}
          mode={"auto"}
          small
          onClick={toggleShowFilters}
        >
          {`${showFilters ? "Скрыть" : "Показать"} фильтры`}
        </CButton>
        <CButton mode={"auto"} onClick={onAddClick} small>
          Добавить пользователя
        </CButton>
      </div>
      <UsersFilters
        show={showFilters}
        filters={filters}
        handleFiltersChange={handleFiltersChange}
      />
      <div className={styles.settings__users_content}>
        <CustomTable
          stickyHeader
          headerHeight={140}
          headItems={headItems}
          isEmpty={!filteredData?.length}
          isFetching={isFetching}
        >
          {filteredData?.map((item) => {
            return (
              <CustomTableRow
                key={item?.employee_id}
                className={
                  activeUserId === item?.employee_id
                    ? styles.settings__users_active
                    : ""
                }
              >
                <CustomTableCell align="left">
                  {item?.employee_id}
                </CustomTableCell>
                <CustomTableCell align="left">{item.fio || ""}</CustomTableCell>
                <CustomTableCell align="left">
                  {!!item.login_url ? (
                    <a
                      href={item.login_url}
                      className={styles.settings__login}
                      rel="noreferrer"
                      target={"_blank"}
                    >
                      {item.email || ""}
                    </a>
                  ) : (
                    item.email
                  )}
                </CustomTableCell>
                <CustomTableCell align="left">
                  {!!item.position && (
                    <span
                      dangerouslySetInnerHTML={{ __html: item.position }}
                    ></span>
                  )}
                </CustomTableCell>
                <CustomTableCell align="left">
                  {item.contragent_name || ""}
                </CustomTableCell>
                <CustomTableCell align="left">
                  {getLocaleDate(item.last_login) || ""}
                </CustomTableCell>
                <CustomTableCell align="center">
                  <Checkbox
                    id={"is_active_modal_check"}
                    checked={!!item.is_active}
                    disabled={loading}
                    onChange={() => actionWithUser(item?.employee_id)}
                  />
                </CustomTableCell>
                <CustomTableCell align="center">
                  <div className={styles.settings__actions}>
                    <EditIconBtn
                      title={"Редактировать пользователя"}
                      onClick={() => onEditClick(item)}
                    />
                    <IconButton
                      title={"Выбрать пользователя"}
                      className={classes.iconButton}
                      onClick={() => changeActiveUser(item)}
                    >
                      <OpenInNewIcon
                        className={clsx(
                          styles.settings__clickable,
                          styles.settings__clickable_icon
                        )}
                      />
                    </IconButton>
                    <IconButton
                      title={"Сбросить пароль"}
                      className={classes.iconButton}
                      onClick={() => onClickReset(item?.employee_id, item?.fio)}
                    >
                      <LockOpenIcon className={styles.settings__password} />
                    </IconButton>
                  </div>
                </CustomTableCell>
              </CustomTableRow>
            );
          })}
        </CustomTable>
      </div>
    </div>
  );
};

const headItems = [
  { label: "ID", align: "left" },
  { label: "ФИО", align: "left" },
  { label: "Логин", align: "left" },
  { label: "Должность", align: "left" },
  { label: "Контрагент", align: "left" },
  { label: "Последний вход", align: "left" },
  { label: "Активный", align: "left" },
  { label: "Действия", align: "center" },
];
