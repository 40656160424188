import React from "react";
import NumberFormat from "react-number-format";
import { TextField } from "@material-ui/core";

type InputSumMaskType = {
  name?: string;
  label?: string;
  value?: number | string;
  onChange?: (e: any) => void;
  error?: string;
  onEnter?: (e: any) => void;
  onBlur?: (e: any) => void;
  helperText?: string;
  placeholder?: string;
  format?: string | null;
  allowLeadingZeros?: boolean;
  allowNegative?: boolean;
  disabled?: boolean;
  min?: number | null;
  max?: number | null;
  className?: string;
  mask?: string;
};

export const InputSumMask = ({
  name,
  label,
  value,
  onChange,
  error,
  onEnter,
  onBlur,
  helperText,
  placeholder,
  format = null,
  allowLeadingZeros = false,
  allowNegative = false,
  disabled = false,
  min = null,
  max = null,
  className = "",
}: InputSumMaskType) => {
  const inputProps: InputSumMaskType = {
    allowNegative,
    allowLeadingZeros,
    min,
    max,
    placeholder,
    disabled,
    value: value === 0 ? 0 : value || "",
    name,
  };
  if (format) {
    inputProps.format = format;
    inputProps.mask = "_";
  }

  const onKeyDown = (e) => {
    if (!onEnter || e?.key !== "Enter") return;
    onEnter(e);
  };

  return (
    <TextField
      fullWidth
      label={label}
      value={value || ""}
      onChange={onChange}
      name={name}
      className={className}
      id={name}
      onKeyDown={onKeyDown}
      onBlur={(e) => onBlur && onBlur(e)}
      InputProps={{
        inputComponent: NumberFormatCustom,
      }}
      inputProps={inputProps}
      helperText={helperText}
      error={!!error}
    />
  );
};

function NumberFormatCustom({
  inputRef,
  onChange,
  min,
  max = null,
  name,
  ...other
}) {
  const isAllowed = (values) => {
    if (max === null || max === undefined) return true;
    return values.floatValue < max;
  };

  return (
    <NumberFormat
      getInputRef={inputRef}
      onBlurCapture={() => {
        if (typeof other.value === "number") return;
        onChange &&
          onChange({
            target: {
              name,
              value: other.value === "" ? "" : Number(other.value) || 0,
            },
          });
      }}
      onValueChange={(values) => {
        const value = values.value;
        const valueSlice = value.slice(0, 2);
        const correctValue =
          value === "0"
            ? 0
            : (valueSlice === "0." ? value : Number(value)) || "";
        onChange({
          target: {
            name,
            value: correctValue,
          },
        });
      }}
      isAllowed={isAllowed}
      min={min === null ? 0 : min}
      allowNegative={false}
      thousandSeparator={" "}
      decimalSeparator={"."}
      allowedDecimalSeparators={[".", ","]}
      {...other}
    />
  );
}
