import { Checkbox, IconButton, TextareaAutosize } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { getCommons } from "../../../../api/services";
import { appState } from "../../../../redux/reducers/appReducer";
import { CommonsType, Employee } from "../../../../types";
import { CButton } from "../../../buttons";
import { AutoSuggest } from "../../../form/AutoSuggest";
import CustomInput from "../../../form/CustomInput";
import { PhoneInput } from "../../../form/InputNumberMask";
import { InputWarning } from "../../../form/warnings";
import { AddEmployeeProps } from "../types";
import styles from "./addEmployee.module.scss";

export const AddEmployee = ({ data, handleClose }: AddEmployeeProps) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const { modalLoading, moduleContragents: contragents } =
    useSelector(appState);
  const [commons, setCommons] = useState<CommonsType>(null);

  useEffect(() => {
    const getData = async () => {
      const res = await getCommons();
      setCommons(res);
    };
    getData();
  }, []);

  const onSubmit = async (body: Employee) => {
    const { onSubmit: submit } = data;
    return (
      submit &&
      submit({ ...body, is_show_all_remonts: body.is_show_all_remonts ? 1 : 0 })
    );
  };

  return (
    <div>
      <div className={styles.add__header}>
        <h2 className={styles.add__title}>{` ${
          data?.body ? "Редактирование" : "Добавление"
        } пользователя`}</h2>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </div>

      <form className={styles.add__form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.add__section_title}>Общие данные</div>
        <div className={styles.add__form_item}>
          <Controller
            name="fio"
            control={control}
            render={({ field: { onChange, value } }) => (
              <CustomInput
                type="text"
                fullWidth
                label="ФИО"
                placeholder="Введите ФИО"
                wrapperClassname={styles.add__input_wrapper}
                value={value}
                onChange={onChange}
              />
            )}
            defaultValue={data?.body?.fio || ""}
            rules={{
              required: true,
            }}
          />
          {errors.fio && <InputWarning>Это поле обязательно</InputWarning>}
        </div>
        <div className={styles.add__form_item}>
          <Controller
            name="email"
            control={control}
            render={({ field: { onChange, value } }) => (
              <CustomInput
                type="text"
                fullWidth
                label="Email"
                placeholder="Введите Email"
                wrapperClassname={styles.add__input_wrapper}
                value={value}
                onChange={onChange}
              />
            )}
            defaultValue={data?.body?.email || ""}
          />
          {errors.email && <InputWarning>Это поле обязательно</InputWarning>}
        </div>
        <div className={styles.add__form_item}>
          <Controller
            name="phone"
            control={control}
            render={({ field: { onChange, value } }) => (
              <PhoneInput
                fullWidth
                variant={"standard"}
                className={""}
                label="Телефон"
                placeholder="Телефон"
                value={value}
                onChange={onChange}
              />
            )}
            defaultValue={data?.body?.phone || ""}
          />
          {errors.phone && <InputWarning>Это поле обязательно</InputWarning>}
        </div>
        <div className={styles.add__form_item}>
          <Controller
            name="comment"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextareaAutosize
                className={""}
                placeholder="Комменатрий"
                value={value}
                onChange={onChange}
                minRows={4}
                style={{ width: "100%" }}
              />
            )}
            defaultValue={data?.body?.comment || ""}
          />
        </div>
        <div className={styles.add__section_title}>Данные по компании</div>
        <div className={styles.add__form_item}>
          <Controller
            name="position_id"
            control={control}
            render={({ field: { onChange, value } }) => (
              <AutoSuggest
                withMargin
                list={commons?.positions}
                showKey={"position_name"}
                valueKey={"position_id"}
                value={value}
                placeholder="Выберите должность"
                label="Выберите должность"
                onChange={onChange}
              />
            )}
            defaultValue={data?.body?.position_id || ""}
          />
        </div>
        {!data?.body && (
          <div className={styles.add__form_item}>
            <Controller
              name="company_id"
              control={control}
              render={({ field: { onChange, value } }) => (
                <AutoSuggest
                  list={commons?.companies}
                  showKey={"company_name"}
                  valueKey={"company_id"}
                  value={value}
                  withMargin
                  placeholder="Выберите компанию"
                  label="Выберите компанию"
                  onChange={(e) => {
                    onChange(e);
                  }}
                />
              )}
              defaultValue={data?.body?.company_id || ""}
            />
            {errors.company_id && (
              <InputWarning>Это поле обязательно</InputWarning>
            )}
          </div>
        )}
        <div className={styles.add__form_item}>
          <Controller
            name="warehouse_id"
            control={control}
            render={({ field: { onChange, value } }) => (
              <AutoSuggest
                withMargin
                list={commons?.warehouses}
                showKey={"warehouse_name"}
                valueKey={"warehouse_id"}
                value={value}
                placeholder="Выберите склад"
                label="Склад"
                onChange={onChange}
              />
            )}
            defaultValue={data?.body?.warehouse_id || ""}
          />
        </div>

        <div className={styles.add__section_title}>Если сотрудник офиса</div>

        <div className={styles.add__form_item}>
          <Controller
            name="office_id"
            control={control}
            render={({ field: { onChange, value } }) => (
              <AutoSuggest
                withMargin
                list={commons?.offices}
                showKey={"office_name"}
                valueKey={"office_id"}
                value={value}
                placeholder="Выберите офис"
                label="Офис"
                onChange={onChange}
              />
            )}
            defaultValue={data?.body?.office_id || ""}
          />
        </div>
        <div className={styles.add__section_title}>
          Если сотрудник отдела продаж
        </div>

        <div className={styles.add__form_item}>
          <Controller
            name="sale_point_id"
            control={control}
            render={({ field: { onChange, value } }) => (
              <AutoSuggest
                withMargin
                list={commons?.sale_points}
                showKey={"point_name"}
                valueKey={"sale_point_id"}
                value={value}
                placeholder="Выберите точку продаж"
                label="Точка продаж"
                onChange={onChange}
              />
            )}
            defaultValue={data?.body?.sale_point_id || ""}
          />
        </div>

        <div className={styles.add__form_item}>
          <Controller
            name="sale_department_id"
            control={control}
            render={({ field: { onChange, value } }) => (
              <AutoSuggest
                withMargin
                list={commons?.sale_departments}
                showKey={"sale_department_name"}
                valueKey={"sale_department_id"}
                value={value}
                placeholder="Выберите отдел продаж"
                label="Отдел продаж"
                onChange={onChange}
              />
            )}
            defaultValue={data?.body?.sale_department_id || ""}
          />
        </div>

        <div className={styles.add__form_item}>
          <Controller
            name="crm_role_id"
            control={control}
            render={({ field: { onChange, value } }) => (
              <AutoSuggest
                withMargin
                list={commons?.crm_roles}
                showKey={"crm_role_name"}
                valueKey={"crm_role_id"}
                value={value}
                placeholder="Выберите должность в CRM"
                label="Должность в CRM"
                onChange={onChange}
              />
            )}
            defaultValue={data?.body?.crm_role_id || ""}
          />
        </div>
        <div className={styles.add__section_title}>
          Если сотрудник подрядчик
        </div>

        <div className={styles.add__form_item}>
          <Controller
            name="contractor_main_id"
            control={control}
            render={({ field: { onChange, value } }) => (
              <AutoSuggest
                withMargin
                list={commons?.contractor_mains}
                showKey={"contractor_main_name"}
                valueKey={"contractor_main_id"}
                value={value}
                placeholder="Выберите главного подрядчика"
                label="Главный подрядчик"
                onChange={onChange}
              />
            )}
            defaultValue={data?.body?.contractor_main_id || ""}
          />
        </div>

        <div className={styles.add__form_item}>
          <Controller
            name="contractor_id"
            control={control}
            render={({ field: { onChange, value } }) => (
              <AutoSuggest
                withMargin
                list={commons?.contractors}
                showKey={"contractor_name"}
                valueKey={"contractor_id"}
                value={value}
                placeholder="Выберите подрядчика"
                label="Подрядчик"
                onChange={onChange}
              />
            )}
            defaultValue={data?.body?.contractor_id || ""}
          />
        </div>

        <div className={styles.add__form_item}>
          <Controller
            name="team_master_id"
            control={control}
            render={({ field: { onChange, value } }) => (
              <AutoSuggest
                withMargin
                list={commons?.team_masters}
                showKey={"team_master_fio"}
                valueKey={"team_master_id"}
                value={value}
                placeholder="Выберите мастера"
                label="Мастер"
                onChange={onChange}
              />
            )}
            defaultValue={data?.body?.team_master_id || ""}
          />
        </div>
        <div className={styles.add__section_title}>
          Если сотрудник поставщик
        </div>
        <div className={styles.add__form_item}>
          <Controller
            name="provider_id"
            control={control}
            render={({ field: { onChange, value } }) => (
              <AutoSuggest
                withMargin
                list={commons?.providers}
                showKey={"provider_name"}
                valueKey={"provider_id"}
                value={value}
                placeholder="Выберите поставщика"
                label="Поставщик"
                onChange={onChange}
              />
            )}
            defaultValue={data?.body?.provider_id || ""}
          />
        </div>
        <div className={styles.add__section_title}>Если сотрудник ОКК</div>
        <div className={styles.add__form_item}>
          <Controller
            name="okk_id"
            control={control}
            render={({ field: { onChange, value } }) => (
              <AutoSuggest
                withMargin
                list={commons?.okk}
                showKey={"okk_name"}
                valueKey={"okk_id"}
                value={value}
                placeholder="Выберите ОКК"
                label="ОКК"
                onChange={onChange}
              />
            )}
            defaultValue={data?.body?.okk_id || ""}
          />
        </div>
        <div className={styles.add__form_item}>
          <label htmlFor={"is_show_all_remonts"}>Показывать все ремонты</label>
          <Controller
            name="is_show_all_remonts"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Checkbox
                id={"is_show_all_remonts"}
                checked={!!value}
                onChange={onChange}
              />
            )}
            defaultValue={data?.body?.is_show_all_remonts === 1}
          />
        </div>
        <div className={styles.add__section_title}>
          Если сотрудник Проектировщик или Менеджер Проекта
        </div>
        <div className={styles.add__form_item}>
          <Controller
            name="techproject_developer_id"
            control={control}
            render={({ field: { onChange, value } }) => (
              <AutoSuggest
                withMargin
                list={commons?.techproject_developers}
                showKey={"techproject_developer_name"}
                valueKey={"techproject_developer_id"}
                value={value}
                placeholder="Выберите контрагент"
                label="Контрагент"
                onChange={onChange}
              />
            )}
            defaultValue={data?.body?.techproject_developer_id || ""}
          />
        </div>
        <div className={styles.add__btn_wrapper}>
          <CButton
            loading={modalLoading}
            disabled={modalLoading}
            type="submit"
            className={styles.add__btn}
          >
            Сохранить
          </CButton>
        </div>
      </form>
    </div>
  );
};
