import instance from "./index";
import cookiesService from "../services/cookiesService";
import { Company } from "../types";

export const checkAuth = () => {
  const cookiesServiceInstance = cookiesService.getService();
  const token = cookiesServiceInstance.getAccessToken();
  return instance(true, null, {
    showToast: false,
    throwError: true,
  }).post(`/auth/api/token/verify/`, { token });
};

export const appAPI = {
  async getCommons() {
    return await instance()
      .get("/core/commons/")
      .then((res) => res?.data);
  },
  async getPositions() {
    return await instance()
      .get("/core/commons/positions/")
      .then((res) => res?.data);
  },
  async getCompanies(): Promise<{ data: Company[] }> {
    return await instance()
      .get("/core/commons/companies/")
      .then((res) => res?.data);
  },
  async getAppCompanies(): Promise<{ data: Company[] }> {
    return await instance()
      .get("/core/companies/read/")
      .then((res) => res?.data);
  },
};
